import first from "../../images/feedback/1.jpeg";
import secound from "../../images/feedback/2.jpeg";
import third from "../../images/feedback/3.png";
export const feedback = [
  {
    text:
      "زيارة شركة AK energy للسيد داود غول والي غازي عنتاب. وتم إطلاع المسؤولين عن سير عمل الشركة والتطرق إلى الصعوبات التي تواجهها ومناقشة سبل تذليلها...",
    name: "داوود غول.",
    position: " والي غازي عينتاب ",
    img: first,
  },
  {
    text:
      "شكر المجلس المحلي لمدينة الباب بالشكر لشركة الطاقة والكهرباء لقاء جهودها المبذولة بزيادة وتكثيف العمل في المدينة واهتمامها بتقديم أفضل الخدمات...",

    position: "المجلس المحلي لمدينة الباب.",
    img: secound,
  },
  {
    text:
      "يشكر المجلس المحلي لمدينة بزاعة إدارة وموظفي شركة الطاقة والكهرباء على جهودهم المبذولة والتعاون اللامحدود في سبيل خدمة المواطنين في المدينة.",
    position: "المجلس المحلي لمدينة بزاعة.",
    img: third,
  },
];
